import * as React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import Layout from '../../components/layout/layout'
import parse from 'html-react-parser'
import Mediapost from "../../components/mediapost/mediapost"
import Adsense from "../../components/adsense/adsense";
const pageTitle = "News 特別報導"

const IndexPage = () => {
    const query = graphql`
      {
        restApiFeaturedApi {
          objects {
            article_type
            content_en
            summary_en
            title_en
            slug
            mode_type
            id
            flag_online
            creation_date_timestamp
            creation_date
            thumbnail_url
          }
        }
      }`

    const objects = useStaticQuery(query)

    return (
        <Layout pageTitle={pageTitle}>
                                    <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto"/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 pt-4">

                        <h1 className="mb-4">{pageTitle}</h1>

                        {objects && objects.restApiFeaturedApi && objects.restApiFeaturedApi.objects ? objects.restApiFeaturedApi.objects.map(post => (
                            <Mediapost post={post} link={`/featured/${post.slug}/`} />
                        )) : null}


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
